import { StatueKeys } from '../statue-helpers/dist';
import { StatueData } from '../utils/statueHelpers';

const GAS_LIMIT = process.env.REACT_APP_DEFAULT_GAS_LIMIT ?? '';
const ROLL_FEE_GWEI = process.env.REACT_APP_ROLL_FEE_GWEI ?? '2';

export const fetchAllTokenIds = async (drizzle: any) => {
  const totalSupply = await drizzle.contracts.RollStatue.methods
    .totalSupply()
    .call();

  return [...new Array(+totalSupply).keys()].map((i) => i + 1);
};

export const getAllOwnerTokenIds = async (drizzle: any, owner: string) => {
  try {
    const ownerIndices = await drizzle.contracts.RollStatue.methods
      .getStatueIndicesByOwner(owner)
      .call();

    const ids: string[] = ownerIndices.map((o: string) => Number(o) + 1);

    return ids;
  } catch (error) {
    throw new Error(`Failed to get owner token ids. Error: ${error}`);
  }
};

export const roll = async (
  drizzle: any,
  from: string,
  statuesData: [StatueData, StatueData, StatueData]
) => {
  const transformToOrderedIndices = (
    data: [StatueData, StatueData, StatueData],
    position: 'top' | 'middle' | 'bottom'
  ) => {
    const copy = Array.from(data);

    const typeKey =
      position === 'top'
        ? StatueKeys.topStatue
        : position === 'middle'
        ? StatueKeys.middleStatue
        : StatueKeys.bottomStatue;

    const colorKey =
      position === 'top'
        ? StatueKeys.topColor
        : position === 'middle'
        ? StatueKeys.middleColor
        : StatueKeys.bottomColor;

    const res = copy
      .sort(
        (a, b) =>
          a.statue[typeKey] - b.statue[typeKey] ||
          a.statue[colorKey] - b.statue[colorKey]
      )
      .map((s) => data.indexOf(s));

    return res;
  };

  const result = await drizzle.contracts.RollStatue.methods
    .roll(...statuesData.map((s) => s.id), [
      ...transformToOrderedIndices(
        [statuesData[0], statuesData[1], statuesData[2]],
        'top'
      ),
      ...transformToOrderedIndices(
        [statuesData[0], statuesData[1], statuesData[2]],
        'middle'
      ),
      ...transformToOrderedIndices(
        [statuesData[0], statuesData[1], statuesData[2]],
        'bottom'
      ),
    ])
    .send({ value: Number(ROLL_FEE_GWEI) * 10 ** 9, from }); // 2 gwei default

  return result;
};

export const transferToken = async (
  drizzle: any,
  tokenId: string,
  from: string,
  to: string
) => {
  console.log('Transferring...');
  await drizzle.contracts.RollStatue.methods
    .transferFrom(from, to, tokenId)
    .send({ gasLimit: GAS_LIMIT, from });
  console.log('Transfer Complete!');
  return 'success';
};

export const getTokenURI = async (drizzle: any, tokenId: string) => {
  const metadataUrl: string = await drizzle.contracts.RollStatue.methods
    .tokenURI(tokenId)
    .call();

  return metadataUrl;
};

export const getCanRoll = async (drizzle: any) => {
  const result = await drizzle.contracts.RollStatue.methods.getCanRoll().call();

  return Boolean(result);
};
