import axios from 'axios';
import { MetaDataDocument } from '../statue-helpers'; // TODO: Fix this (current GCP issue);
import { mockStatues } from './mockStatues';

const API_URL = process.env.REACT_APP_API_URL || '';

export const getAllMetadata = async (ids?: string[]) => {
  // const res = await axios.get<Array<Exclude<MetaDataDocument, 'attributes'>>>(
  //   API_URL + 'statues' + (!ids ? '' : `?ids=${ids?.join(',')}`)
  // );
  // return res.data;

  if (ids && ids.length > 0) {
    return mockStatues.filter((s) => ids.includes(String(s._id)));
  }

  return mockStatues;
};

export const getMetadata = async (url: string) => {
  const res = await axios.get<MetaDataDocument>(url);
  return res.data;
};

export const getPendingRendersDna = async (ownerAddress: string) => {
  const res = await axios.get<string[]>(
    API_URL + 'renderer/pending/' + ownerAddress
  );
  return res.data;
};

export const triggerRender = async (requestId: string) => {
  const res = await axios.post<{ status: 'success' | 'failure' }>(
    API_URL + 'renderer/request/' + requestId
  );
  return res.data;
};

export const getAllReadyTimes = async (ids?: string[]) => {
  const res = await axios.get<Record<string, string>>(
    API_URL + 'statues/readyTime' + (!ids ? '' : `?ids=${ids?.join(',')}`)
  );
  return res.data;
};
