"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkIsComplete = exports.checkIsMonochrome = exports.encodeMetaData = exports.decodeDna = exports.encodeDna = void 0;
var types_1 = require("./types");
var partToTraightMap = (_a = {},
    _a[types_1.StatueKeys.bgColor] = { traight: 'Background Color', enumType: types_1.BgColor },
    _a[types_1.StatueKeys.bgObjectOne] = {
        traight: 'Background Object 1',
        enumType: types_1.BgObject,
    },
    _a[types_1.StatueKeys.bgObjectTwo] = {
        traight: 'Background Object 2',
        enumType: types_1.BgObject,
    },
    _a[types_1.StatueKeys.topStatue] = { traight: 'Top Statue', enumType: types_1.StatueType },
    _a[types_1.StatueKeys.middleStatue] = { traight: 'Middle Statue', enumType: types_1.StatueType },
    _a[types_1.StatueKeys.bottomStatue] = { traight: 'Bottom Statue', enumType: types_1.StatueType },
    _a[types_1.StatueKeys.topColor] = { traight: 'Top Color', enumType: types_1.StatueColor },
    _a[types_1.StatueKeys.middleColor] = { traight: 'Middle Color', enumType: types_1.StatueColor },
    _a[types_1.StatueKeys.bottomColor] = { traight: 'Bottom Color', enumType: types_1.StatueColor },
    _a);
function encodeDna(statue, order) {
    if (order === void 0) { order = types_1.dnaOrder; }
    return order.reduce(function (accum, dnaPart) { return accum + String(statue[dnaPart]).padStart(2, '0'); }, '');
}
exports.encodeDna = encodeDna;
function decodeDna(dna, order) {
    if (order === void 0) { order = types_1.dnaOrder; }
    var parts = [];
    for (var i = 0; i < dna.length - 1; i = i + 2) {
        parts.push(dna[i] + dna[i + 1]);
    }
    return order.reduce(function (accum, dnaPart, index) {
        var _a;
        return (__assign(__assign({}, accum), (_a = {}, _a[types_1.StatueKeys[dnaPart]] = +parts[index], _a)));
    }, {});
}
exports.decodeDna = decodeDna;
// TODO: consider just having all const mappings instead of transforming the enum key
// TODO: fix cases with multiple words
function encodeMetaData(dna, imageUrl, videoUrl, generation, arweave, id, externalUrl, order) {
    if (order === void 0) { order = types_1.dnaOrder; }
    var statue = decodeDna(dna);
    return {
        name: dna,
        description: id <= 81 ? 'This statue is part of the original 81' : 'Rolled Statue',
        image: imageUrl,
        animation_url: videoUrl,
        external_url: externalUrl + id,
        arweave: arweave,
        attributes: __spreadArrays(order.map(function (part) { return ({
            trait_type: partToTraightMap[part].traight,
            value: partToTraightMap[part].enumType[statue[part]].toLowerCase(),
        }); }), [
            { display_type: 'number', trait_type: 'Generation', value: generation },
            { trait_type: 'Monochrome', value: "" + checkIsMonochrome(dna) },
            { trait_type: 'Complete', value: "" + checkIsComplete(dna) },
        ]),
    };
}
exports.encodeMetaData = encodeMetaData;
function checkEqualSubDna(subDna) {
    var first = subDna.slice(0, 2);
    var second = subDna.slice(2, 4);
    var third = subDna.slice(4);
    return first === second && second === third;
}
function checkIsMonochrome(dna) {
    var rawColorParts = dna.slice(12);
    return checkEqualSubDna(rawColorParts);
}
exports.checkIsMonochrome = checkIsMonochrome;
function checkIsComplete(dna) {
    var rawTypeParts = dna.slice(6, 12);
    return checkEqualSubDna(rawTypeParts);
}
exports.checkIsComplete = checkIsComplete;
