import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import AppShell from '../AppShell';
import StatueDetail from '../StatueDetail';
import NotFoundPage from '../NotFoundPage';
import GalleryPage from '../GalleryPage';
import InfoPage from '../InfoPage';
import DragonPath from '../DragonPath';
import OwnerCollection from '../OwnerCollection';
import AdminTools from '../AdminTools';
import { shouldShowPreview } from '../../utils/envHelpers';

function MainRouter() {
  return (
    <Switch>
      <Route path="/browse/:statueId">
        <StatueDetail />
      </Route>
      <Route path="/browse">
        <GalleryPage />
      </Route>
      <Route path="/collect">
        <OwnerCollection />
      </Route>
      {/* TODO: TEST ONLY -- Remove This */}
      <Route path="/debug">
        <AdminTools />
      </Route>
      <Route path="/info">
        <InfoPage />
      </Route>
      <Route path="/dragonpath">
        <DragonPath />
      </Route>
      <Route path="/" exact>
        <GalleryPage />
      </Route>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}

function MaintenanceRouter() {
  return (
    <>
      <Redirect from="*" to="/" />
      <Switch>
        <Route path="/">
          <InfoPage />
        </Route>
      </Switch>
    </>
  );
}

export default function App() {
  return (
    <Router>
      <AppShell>
        {shouldShowPreview ? <MaintenanceRouter /> : <MainRouter />}
      </AppShell>
    </Router>
  );
}
