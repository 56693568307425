import React from 'react';
import './index.css';

export default function InfoPage() {
  return (
    <div className="DragonPath">
      <div className="main-container">
        <h1 className="title">THE DRAGON PATH</h1>
        <div className="box_content">
          <p>
            The dragon path leads to hidden statue fragments that were not
            revealed in the initial 81 art works. Collectors can discover statue
            fragments of the guardian, the assassin, and the enforcer by
            breeding special combinations of their original pieces.
          </p>
        </div>
        <h1>HOW IT WORKS</h1>
        <div className="box_content">
          <p>
            The guardian, the assassin, and the enforcer statues are considered
            tier 1 statues, and the dragon statue is the only tier 2 statue.
            Like the original statues, tier statues are broken into three
            fragments. By breeding tokens with specific TOP fragments, one can
            discover a TOP piece of one of the tier statues. Users can use the
            same logic to find MIDDLE and BOTTOM tier statue fragments as well.
            The chance of encountering the hidden fragment is roughly 20% with
            each successful breed. Follow the path and discover TOP, MIDDLE and
            BOTTOM tier 1 statue fragments!
          </p>
          <img
            className="image"
            src="https://storage.googleapis.com/cryptogifs/html/images/info/dragonpath_1.png"
            alt=""
          />
        </div>

        <h1>TOP FRAGMENTS</h1>
				<div className="code">
					<p>Clementine Hermanubis + Gold Conch + Clementine Astronaut --&gt; Marble Guardian</p>
					<p>Sky Conch + Chrome Conch + Sky Adventurer --&gt; Clementine Guardian</p>
					<p>Shadow Thorn + Marble Soldier + Marble Eagle --&gt; Rose Guardian</p>
					<p>Sky Thorn + Chrome Thorn + Pearl Hermanubis --&gt; Sky Guardian</p>
					<p>Marble Puck + Cloud Puck + Sky Hermanubis --&gt; Shadow Guardian</p>
					<p>Shadow Soldier + Clementine Soldier + Shadow Conch --&gt; Cloud Guardian</p>
					<p>Shadow Puck + Cloud Adventurer + Gold Hermanubis --&gt; Pearl Guardian</p>
					<p>Gold Eagle + Chrome Puck + Cloud Conch --&gt; Chrome Guardian</p>
					<p>Sky Astronaut + Rose Adventurer + Marble Shepherd --&gt; Gold Guardian</p>
					<p>Pearl Astronaut + Shadow Hermanubis + Rose Eagle --&gt; Marble Assassin</p>
					<p>Chrome Astronaut + Chrome Eagle + Marble Astronaut --&gt; Clementine Assassin</p>
					<p>Shadow Eagle + Pearl Adventurer + Shadow Astronaut --&gt; Rose Assassin</p>
					<p>Clementine Conch + Cloud Shepherd + Chrome Hermanubis --&gt; Sky Assassin</p>
					<p>Marble Thorn + Sky Eagle + Gold Astronaut --&gt; Shadow Assassin</p>
					<p>Sky Shepherd + Chrome Soldier + Pearl Shepherd --&gt; Cloud Assassin</p>
					<p>Clementine Eagle + Gold Thorn + Rose Shepherd --&gt; Pearl Assassin</p>
					<p>Sky Puck + Shadow Shepherd + Gold Shepherd --&gt; Chrome Assassin</p>
					<p>Cloud Thorn + Cloud Soldier + Cloud Hermanubis --&gt; Gold Assassin</p>
					<p>Clementine Thorn + Clementine Shepherd + Rose Soldier --&gt; Marble Enforcer</p>
					<p>Chrome Shepherd + Pearl Soldier + Pearl Conch --&gt; Clementine Enforcer</p>
					<p>Shadow Adventurer + Rose Astronaut + Pearl Thorn --&gt; Rose Enforcer</p>
					<p>Pearl Eagle + Rose Hermanubis + Marble Conch --&gt; Sky Enforcer</p>
					<p>Rose Conch + Chrome Adventurer + Cloud Astronaut --&gt; Shadow Enforcer</p>
					<p>Rose Puck + Clementine Puck + Cloud Eagle --&gt; Cloud Enforcer</p>
					<p>Sky Soldier + Clementine Adventurer + Marble Adventurer --&gt; Pearl Enforcer</p>
					<p>Rose Thorn + Pearl Puck + Gold Puck --&gt; Chrome Enforcer</p>
					<p>Gold Soldier + Gold Adventurer + Marble Hermanubis --&gt; Gold Enforcer</p>
				</div>

				<h1>MIDDLE FRAGMENTS</h1>
				<div className="code">
					<p>Gold Thorn + Sky Astronaut + Pearl Shepherd --&gt; Marble Guardian</p>
					<p>Cloud Conch + Chrome Shepherd + Cloud Eagle --&gt; Clementine Guardian</p>
					<p>Rose Adventurer + Marble Soldier + Shadow Hermanubis --&gt; Rose Guardian</p>
					<p>Chrome Eagle + Gold Soldier + Marble Conch --&gt; Sky Guardian</p>
					<p>Chrome Soldier + Sky Thorn + Pearl Eagle --&gt; Shadow Guardian</p>
					<p>Cloud Astronaut + Cloud Thorn + Pearl Conch --&gt; Cloud Guardian</p>
					<p>Pearl Hermanubis + Marble Eagle + Cloud Puck --&gt; Pearl Guardian</p>
					<p>Gold Astronaut + Clementine Eagle + Marble Puck --&gt; Chrome Guardian</p>
					<p>Shadow Adventurer + Rose Shepherd + Cloud Adventurer --&gt; Gold Guardian</p>
					<p>Chrome Conch + Sky Shepherd + Pearl Thorn --&gt; Marble Assassin</p>
					<p>Sky Soldier + Chrome Adventurer + Marble Adventurer --&gt; Clementine Assassin</p>
					<p>Clementine Adventurer + Rose Hermanubis + Clementine Conch --&gt; Rose Assassin</p>
					<p>Rose Eagle + Shadow Shepherd + Rose Conch --&gt; Sky Assassin</p>
					<p>Gold Hermanubis + Shadow Soldier + Marble Astronaut --&gt; Shadow Assassin</p>
					<p>Marble Thorn + Cloud Soldier + Rose Puck --&gt; Cloud Assassin</p>
					<p>Shadow Conch + Rose Soldier + Cloud Hermanubis --&gt; Pearl Assassin</p>
					<p>Clementine Astronaut + Chrome Hermanubis + Chrome Astronaut --&gt; Chrome Assassin</p>
					<p>Sky Conch + Cloud Shepherd + Gold Puck --&gt; Gold Assassin</p>
					<p>Sky Puck + Sky Adventurer + Shadow Eagle --&gt; Marble Enforcer</p>
					<p>Clementine Shepherd + Sky Hermanubis + Gold Adventurer --&gt; Clementine Enforcer</p>
					<p>Pearl Soldier + Rose Thorn + Chrome Puck --&gt; Rose Enforcer</p>
					<p>Clementine Hermanubis + Marble Hermanubis + Shadow Thorn --&gt; Sky Enforcer</p>
					<p>Clementine Soldier + Marble Shepherd + Shadow Astronaut --&gt; Shadow Enforcer</p>
					<p>Clementine Thorn + Pearl Adventurer + Pearl Puck --&gt; Cloud Enforcer</p>
					<p>Gold Shepherd + Chrome Thorn + Clementine Puck --&gt; Pearl Enforcer</p>
					<p>Rose Astronaut + Shadow Puck + Gold Conch --&gt; Chrome Enforcer</p>
					<p>Sky Eagle + Gold Eagle + Pearl Astronaut --&gt; Gold Enforcer</p>
				</div>

				<h1>BOTTOM FRAGMENTS</h1>
				<div className="code">
					<p>Gold Astronaut + Cloud Adventurer + Sky Astronaut --&gt; Marble Guardian</p>
					<p>Chrome Hermanubis + Clementine Conch + Sky Thorn --&gt; Clementine Guardian</p>
					<p>Sky Eagle + Rose Conch + Clementine Thorn --&gt; Rose Guardian</p>
					<p>Rose Thorn + Chrome Shepherd + Pearl Hermanubis --&gt; Sky Guardian</p>
					<p>Clementine Shepherd + Gold Shepherd + Gold Soldier --&gt; Shadow Guardian</p>
					<p>Clementine Adventurer + Shadow Conch + Cloud Hermanubis --&gt; Cloud Guardian</p>
					<p>Marble Puck + Sky Conch + Pearl Puck --&gt; Pearl Guardian</p>
					<p>Sky Shepherd + Pearl Soldier + Clementine Puck --&gt; Chrome Guardian</p>
					<p>Gold Thorn + Cloud Soldier + Chrome Adventurer --&gt; Gold Guardian</p>
					<p>Sky Puck + Chrome Soldier + Gold Hermanubis --&gt; Marble Assassin</p>
					<p>Cloud Astronaut + Gold Adventurer + Rose Astronaut --&gt; Clementine Assassin</p>
					<p>Marble Thorn + Pearl Eagle + Cloud Eagle --&gt; Rose Assassin</p>
					<p>Gold Puck + Chrome Puck + Pearl Astronaut --&gt; Sky Assassin</p>
					<p>Rose Puck + Gold Eagle + Pearl Shepherd --&gt; Shadow Assassin</p>
					<p>Clementine Eagle + Sky Soldier + Pearl Thorn --&gt; Cloud Assassin</p>
					<p>Shadow Eagle + Clementine Hermanubis + Cloud Puck --&gt; Pearl Assassin</p>
					<p>Rose Soldier + Shadow Thorn + Marble Adventurer --&gt; Chrome Assassin</p>
					<p>Pearl Conch + Marble Hermanubis + Cloud Thorn --&gt; Gold Assassin</p>
					<p>Rose Adventurer + Clementine Soldier + Chrome Eagle --&gt; Marble Enforcer</p>
					<p>Sky Adventurer + Marble Shepherd + Cloud Conch --&gt; Clementine Enforcer</p>
					<p>Rose Hermanubis + Rose Eagle + Shadow Astronaut --&gt; Rose Enforcer</p>
					<p>Shadow Adventurer + Chrome Conch + Gold Conch --&gt; Sky Enforcer</p>
					<p>Clementine Astronaut + Sky Hermanubis + Pearl Adventurer --&gt; Shadow Enforcer</p>
					<p>Chrome Astronaut + Shadow Puck + Cloud Shepherd --&gt; Cloud Enforcer</p>
					<p>Shadow Soldier + Marble Eagle + Marble Conch --&gt; Pearl Enforcer</p>
					<p>Rose Shepherd + Marble Astronaut + Marble Soldier --&gt; Chrome Enforcer</p>
					<p>Shadow Hermanubis + Shadow Shepherd + Chrome Thorn --&gt; Gold Enforcer</p>
				</div>

        <h1>THE DRAGON'S DEN</h1>
				<div className="box_content">		 
				<p>
				The key to discovering the dragon rests within fragments of the assassin, the enforcer, and the guardian. The tier 1 beasts each embody characteristics of the dragon, and only their combined presence can unlock from the mystical creature.</p><p>Follow the final steps of the dragon path by combining TOPs, MIDDLEs, and BOTTOMs of tier 1 statues to unlock the respective TOP, MIDDLE and BOTTOM dragon fragments. The same logic in discovering tier 1 fragments applies to finding the tier 2 dragon fragments.
				</p>
				</div>

			<h1>TOP TIER 1 FRAGMENTS</h1>
			<div className="code">
				<p>Cloud Assassin + Clementine Enforcer + Chrome Guardian --&gt; Marble Dragon</p>
				<p>Rose Assassin + Cloud Guardian + Marble Enforcer --&gt; Clementine Dragon</p>
				<p>Clementine Guardian + Rose Enforcer + Pearl Assassin --&gt; Rose Dragon</p>
				<p>Gold Assassin + Sky Guardian + Cloud Enforcer --&gt; Sky Dragon</p>
				<p>Chrome Assassin + Pearl Guardian + Shadow Enforcer --&gt; Shadow Dragon</p>
				<p>Clementine Assassin + Sky Enforcer + Shadow Guardian --&gt; Cloud Dragon</p>
				<p>Rose Guardian + Gold Enforcer + Sky Assassin --&gt; Pearl Dragon</p>
				<p>Chrome Enforcer + Gold Guardian + Marble Assassin --&gt; Chrome Dragon</p>
				<p>Marble Guardian + Pearl Enforcer + Shadow Assassin --&gt; Gold Dragon</p>
			</div>
			<h1>MIDDLE TIER 1 FRAGMENTS</h1>
			<div className="code">
				<p>Rose Assassin + Clementine Guardian + Sky Enforcer --&gt; Marble Dragon</p>
				<p>Pearl Assassin + Marble Guardian + Rose Enforcer --&gt; Clementine Dragon</p>
				<p>Gold Assassin + Shadow Guardian + Gold Enforcer --&gt; Rose Dragon</p>
				<p>Pearl Guardian + Marble Assassin + Clementine Enforcer --&gt; Sky Dragon</p>
				<p>Cloud Enforcer + Sky Guardian + Clementine Assassin --&gt; Shadow Dragon</p>
				<p>Pearl Enforcer + Rose Guardian + Cloud Assassin --&gt; Cloud Dragon</p>
				<p>Chrome Enforcer + Cloud Guardian + Shadow Assassin --&gt; Pearl Dragon</p>
				<p>Shadow Enforcer + Chrome Assassin + Gold Guardian --&gt; Chrome Dragon</p>
				<p>Sky Assassin + Marble Enforcer + Chrome Guardian --&gt; Gold Dragon</p>
			</div>
			<h1>BOTTOM TIER 1 FRAGMENTS</h1>
			<div className="code">
				<p>Chrome Guardian + Pearl Assassin + Shadow Enforcer --&gt; Marble Dragon</p>
				<p>Rose Assassin + Pearl Enforcer + Marble Guardian --&gt; Clementine Dragon</p>
				<p>Sky Assassin + Shadow Guardian + Marble Enforcer --&gt; Rose Dragon</p>
				<p>Pearl Guardian + Cloud Assassin + Chrome Enforcer --&gt; Sky Dragon</p>
				<p>Rose Enforcer + Clementine Guardian + Shadow Assassin --&gt; Shadow Dragon</p>
				<p>Marble Assassin + Cloud Enforcer + Sky Guardian --&gt; Cloud Dragon</p>
				<p>Cloud Guardian + Gold Assassin + Clementine Enforcer --&gt; Pearl Dragon</p>
				<p>Gold Enforcer + Chrome Assassin + Rose Guardian --&gt; Chrome Dragon</p>
				<p>Clementine Assassin + Gold Guardian + Sky Enforcer --&gt; Gold Dragon</p>
			</div>
      </div>
    </div>
  );
}
