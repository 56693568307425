import React from 'react';
import './index.css';

export default function InfoPage() {
  return (
    <div className="InfoPage">
      <div className="main-container">
        <h1>INTRODUCTION TO GLUEWAVE</h1>

        <div className="box_content">
          <p>
            GLUEWAVE is a series of 81 artworks that capture the pastel and
            sun-rising essence of pop culture's vaporwave. Each art piece is
            composed of rubberized statue fragments that are magnetized towards
            one another other, culminating in collision and disarray. As the
            entropy of the scene reaches an apex, gravity is suddenly turned
            off, and in front of you rest a precarious creation, glued together,
            weightless, and beautiful.
          </p>
          <video autoPlay playsInline muted loop className="intro-video">
            <source
              src="https://storage.googleapis.com/cryptogifs/html/images/001516040001070607.mp4"
              type="video/mp4"
            />
            Your browser does not support HTML5 video.
          </video>
        </div>

        <div className="box_content">
          <p>
            These 81 GLUEWAVE artworks are special, not just for their
            entrancing details, but for their ability to propagate. Underneath
            all the chaos is a DNA sequence that encodes the top, middle and
            bottoms statue fragments. A triplet of artworks can comingle their
            DNA and generate a new piece, one never-before-seen.
          </p>
          <table className="container">
            <tbody>
              <tr>
                <td>
                  <video autoPlay playsInline muted loop>
                    <source
                      src="https://storage.googleapis.com/cryptogifs/html/images/040913010102040808.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support HTML5 video.
                  </video>
                </td>
                <td>+</td>
                <td>
                  <video autoPlay playsInline muted loop>
                    <source
                      src="https://storage.googleapis.com/cryptogifs/html/images/000503000807020208.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support HTML5 video.
                  </video>
                </td>
                <td>+</td>
                <td>
                  <video autoPlay playsInline muted loop>
                    <source
                      src="https://storage.googleapis.com/cryptogifs/html/images/010013040800020402.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support HTML5 video.
                  </video>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="box_content">
          <p>
            GLUEWAVE collectors can intelligently breed their pieces and
            reassemble their statues. However, this feat will not be easy;
            completed statues will be exceedingly rare. Those who seek an even
            more arduous challenge can follow the secret dragon path, where
            unrevealed statues are unlocked.
          </p>
          <img
            className="combine-image"
            src="https://storage.googleapis.com/cryptogifs/html/images/info/3x.png"
            alt=""
          />
        </div>

        <h2>WHAT ARE THE ODDS?</h2>
        <div className="box_content">
          <p>
            By breeding artworks that contain similar statue fragments, there is
            a small chance of completing the statue. However, there is a much
            better chance of getting two thirds of the way there. Combining 2/3
            artworks greatly increases your chance of completing a statue.
          </p>
          <img
            src="https://storage.googleapis.com/cryptogifs/html/images/info/odds_1.png"
            className="percent-image"
            alt=""
          />
          <img
            src="https://storage.googleapis.com/cryptogifs/html/images/info/odds_3.png"
            className="percent-image"
            alt=""
          />
          <img
            src="https://storage.googleapis.com/cryptogifs/html/images/info/odds_4.png"
            className="percent-image"
            alt=""
          />
        </div>
        <div className="box_content">
          <p>
            Newly generated artworks will have random background elements and
            colors. Additionally, there is a 3% chance of finding a random
            statue fragment that did not appear among the parent pieces. There
            are over 100,000,000 possible artwork combinations so there will
            always be new and satisfying interactions to behold.
          </p>
        </div>

        <h2>TECHNOLOGY BEHIND GLUEWAVE</h2>

        <p>
          GLUEWAVE leverages the full power of ERC721 Ethereum protocols to
          allow for tradable and propagative art. All the breeding mechanics are
          encoded on the GLUEWAVE smart contracts, and the art files are stored
          on the AR Weave blockchain to ensure their permeance. Each art piece
          and its respective DNA are linked to a tradable GLUEWAVE NFT.
        </p>

        <p>
          The render farm provided the foundation to making virtually unlimited
          art combinations. When a collector breeds a new GLUEWAVE piece, it is
          rendered in real time according to the DNA. However, if the render
          farm is occupied, the request is delegated to a render queue. When the
          farm is available, rendering takes 1-2 hours. The cost of generating
          new NFTs will vary with rendering and Ethereum gas prices.
        </p>

        <img
          src="https://storage.googleapis.com/cryptogifs/html/images/info/lineup2.png"
          className="statue-group-image"
          alt=""
        />
        <p>
          At launch, there will be 81 GLUEWAVE art pieces featuring a random
          assortment of 9 statues in 9 colors. Every statue fragment contributes
          to a both a completed statue and to the dragon path.
        </p>

        <p>
          The GLUEWAVE team hopes to push the bar in 3D digital art
          collectability and utility. We hope you can join us in this endeavor.
          Best of luck breeding!
        </p>

        <hr />

        <h3>TEAM</h3>
        <div className="team-info">
          <p>Steven - Artist, 15+ years in 3d animation</p>
          <p>Maxime - Project Manager, 2+ years in NFT collecting</p>
          <p>Vince - Developer, 5+ years in full-stack development</p>
        </div>

        <p className="footer">
          The scans used in this project are sourced from historical artifacts
          made possible by the work of Oliver Laric with generous support from:
          Lafayette Anticipation — Fondation d’entreprise Galeries Lafayette,
          Paris Secession, Vienna The Contemporary Art Society, London Entrée,
          Bergen
        </p>
      </div>
    </div>
  );
}
